





















































































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { PostDetailViewmodel } from '@/modules/community/home/viewmodels/post-detail-viewmodel'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { walletStore } from '@/stores/wallet-store'
import { when } from 'mobx'

@Observer
@Component({
  components: {
    'avatar-container': () => import('@/modules/community/common/components/avatar.vue'),
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
    'post-list-item': () => import('@/modules/community/common/components/post-list-item.vue'),
    'owner-section': () => import('@/modules/common/components/section/owner-section.vue'),
    'my-dao-section': () => import('@/modules/common/components/section/my-dao-section.vue'),
    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
  },
})
export default class Post extends Vue {
  @Provide() vm = new PostDetailViewmodel()
  createPostController = createPostController
  postController = postController
  wallet = walletStore
  focusing = ''

  @Watch('$route.params.postid', { immediate: true })
  postIdChanged() {
    this.vm.fetchData(this.$route.params.postid)
  }

  @Watch('$route.query.comment', { immediate: true })
  async commentMention(value) {
    if (value) {
      await when(() => !!this.vm.postStore)
      this.vm.postStore?.changeEnableComment()
      this.focusing = value
    }
  }

  startingPost() {
    this.vm.changeShowEncouragePost(false)
    createPostController.show(true)
  }
  pushBackward() {
    this.$router.go(-1)
  }
  destroyed() {
    this.vm.destroy()
  }
}
