import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { PostsStore } from '@/stores/posts-store'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'

export class PostDetailViewmodel {
  @observable postsStore?: PostsStore
  @observable loadingPost = false
  @observable loadingFollow = false
  @observable postStore?: PostStore = undefined
  @observable isNoPost = false
  @observable isNoPostInSomeDays = false
  @observable forceShowEncouragePost = true

  private _disposers: IReactionDisposer[]

  constructor() {
    this._disposers = [
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            this.fetchData(this.postStore?.post?.id)
            createPostController.changeCompletedUpdateType(undefined)
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (!type) return
          if (type === 'delete' || type === 'block') appProvider.router.push('/community/my-page')
          else this.fetchData(this.postStore?.post?.id)
          postController.changeCompleteUpdateType(undefined)
        }
      ),
      reaction(
        () => walletStore.userProfile,
        (profile) => {
          if (profile) {
            this.checkLastestPost()
            this.isNoPost = profile.totalPosts === 0
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ]
  }

  @asyncAction *checkLastestPost() {
    try {
      const posts = yield apiService.posts.find(
        { profile: walletStore.userProfile?._id, status: 'public' },
        { _limit: 1, _sort: 'createdAt:DESC' }
      )
      const post = posts[0]
      if (post?.createdAt) {
        const targetTime = moment(post?.createdAt).add(3, 'days')
        this.isNoPostInSomeDays = !moment().isBefore(targetTime)
        return
      }
      this.isNoPostInSomeDays = false
    } catch (e) {
      console.log('Error checkLastestPost', e)
    }
  }

  @action changeShowEncouragePost(value) {
    this.forceShowEncouragePost = value
  }

  @computed get isShowEncouragePost() {
    return this.forceShowEncouragePost && (this.isNoPost || this.isNoPostInSomeDays)
  }

  destroy() {
    this.postsStore?.destroy()
    this._disposers.forEach((d) => d())
  }

  @asyncAction *fetchData(_id?: string) {
    try {
      if (!_id) return
      this.loadingPost = true
      const posts = yield apiService.posts.fetchPosts({ id: _id }, { _limit: 1 })
      this.postStore = posts?.length ? new PostStore(posts[0]) : undefined
      yield apiService.posts.postViewIncrement(_id)
    } catch (e) {
      console.log('err', e)
    } finally {
      this.loadingPost = false
    }
  }
}
